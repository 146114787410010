<template>
  <div class="recognition-page-three">
    <PhotoGame
      :imgList="imgList"
      :buttonList="buttonList"
      :titleInfo="titleInfo"
      @changeTheBgImg="changeTheBgImg"
    />
  </div>
</template>

<script>
import PhotoGame from "@/components/Course/GamePage/PhotoGame";
export default {
  data() {
    return {
      imgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-6-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-6-2.svg"),
          hasClicked: false,
          position: 0,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-7-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-7-2.svg"),
          hasClicked: false,
          position: 1,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-8-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-8-2.svg"),
          hasClicked: false,
          position: 0,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-9-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-9-2.svg"),
          hasClicked: false,
          position: 1,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-10-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/photo-10-2.svg"),
          hasClicked: false,
          position: 0,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/menu-2.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/menu-2.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/menu-2.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-28/menu-2.svg"),
          startNumberRange: 5,
          endNumberRange: 5,
        },
      ],
      titleInfo: {
        pinyin: "Zhè shì shéi de?",
        hanzi: "这是谁的?",
      },
    };
  },
  components: {
    PhotoGame,
  },
  methods: {
    changeTheBgImg(index) {
      this.imgList[index - 1].hasClicked = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-three {
  width: 100%;
  height: 100%;
}
</style>
