<template>
  <div class="game-container layout-border">
    <FindStrokeTrain
      :targetStrokeImg="targetStrokeImg"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :strokePinyin="strokePinyin"
      @showStroke="handleShowStroke"
      :shadowImg="shadowImg"
      :headerBgImg="headerBgImg"
    />
  </div>
</template>

<script>
import FindStrokeTrain from "@/components/Course/GamePage/FindStrokeTrainGame";
export default {
  data() {
    return {
      headerBgImg: {
        defaultImg:require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/lawn-1.svg"),
        firstImg:require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/lawn-2.svg"),
        secondImg:require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/lawn-3.svg"),
        thirdImg:require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/lawn-4.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/grass.svg"),
      targetStrokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/28-shuzhezhegou-white.svg"),
      strokePinyin: "shùzhézhégōu",
      gameIndex: 28,
      lightIndex: [1, 2, 3],
      hanziStrokeInfo: [
        {
          pinyin: "hào",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/hao-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/hao-1.svg"),
              isShow: false,
              index: 1,
            },
          ],
        },
        {
          pinyin: "chuǎng",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/chuang-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/chuang-1.svg"),
              isShow: false,
              index: 2,
            },
            
          ],
        },
        {
          pinyin: "jī",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/ji-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-28/ji-1.svg"),
              isShow: false,
              index: 3,
            },
            
          ],
        },
      ],
    };
  },
  components: {
    FindStrokeTrain,
  },
  methods: {
    handleShowStroke(index, indexSon) {
      this.hanziStrokeInfo[index].strokeFinishImgList[indexSon].isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>